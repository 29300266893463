<template>
    <div class="all">
        <el-image fit="contain" class="all-image" :src="require('@/assets/image/404.gif')"></el-image>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.all{
    text-align: center;
}
.all-image{
    width: 1000px;
}
</style>